@tailwind base;
@tailwind components;
@tailwind utilities;

.dark{
	background-color: #1a1a1a;
}

.rounded-head:first-child {
	border-radius: 0.5rem 0 0 0.5rem;
}

.rounded-head:last-child {
	border-radius: 0 0.5rem 0.5rem 0;
}

